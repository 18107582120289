import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import { Grid, Cell, Button } from "../../components/ui";
import {
  ContentObject,
  ContentItem,
  FormattedContentItem,
} from "../../components/content";

import { useStyletron } from "baseui";
import { DisplayLarge, ParagraphLarge } from "baseui/typography";

import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";

import { useTheme } from "../../contexts/theme";

const Contact = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();
  const [isSticked, setIsSticked] = useState(false);

  let Motus;

  if (typeof window !== "undefined" && window) Motus = require("motus");

  const onScroll = () => {
    if (
      window.scrollY >
        document.querySelector("[data-csweb=home-shine]").offsetTop + 100 &&
      window.scrollY <
        document.querySelector("[data-csweb=home-shine]").offsetTop +
          document.querySelector("[data-csweb=home-shine]").scrollHeight -
          window.innerHeight
    )
      setIsSticked(true);
    else setIsSticked(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    if (typeof window !== "undefined") {
      if (Motus && Motus.default && Motus.default.Animation) {
        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=home-shine]").offsetTop + 100,
            endPoint:
              document.querySelector("[data-csweb=home-shine]").offsetTop +
              window.innerHeight,
            $el: document.querySelector("[data-csweb=home-contact]"),
            keyframes: [{ opacity: 0 }, { opacity: 1 }],
          })
        );
      }
    }
  }, []);

  useEffect(
    () => () => {
      Motus.default.clearAnimations();

      window.removeEventListener("scroll", onScroll);
    },
    []
  );

  return (
    <>
      <article
        visibility-sensor="false"
        data-csweb="home-contact"
        className={css({
          position: isSticked ? "fixed" : "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          zIndex: 1,
          ...(isSticked && {
            left: "0px",
            top: "0px",
            right: "0px",
            bottom: "0px",
          }),
          [theme.breakpoints.mediaQueries.large]: {
            position: "relative !important",
            opacity: "1 !important",
            paddingTop: theme.sizing.scale100,
            paddingBottom: theme.sizing.scale100,
          },
        })}
      >
        <Grid $style={{ minWidth: "100%" }}>
          <Cell skip={[2, 4, 6]} span={[2, 4, 6]}>
            <div className={css({})}>
              <div
                className={css({
                  position: "absolute",
                  zIndex: "0",
                  marginTop: "10%",
                  width: "100%",
                  height: "70vh",
                  opacity: "0.3",
                  backgroundColor: theme.colors.backgroundSecondary,
                })}
              ></div>
            </div>
          </Cell>
          <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
            <div
              className={css({
                paddingTop: theme.sizing.scale4800,
                paddingBottom: theme.sizing.scale4800,
                position: "relative",
                zIndex: "4",
                [theme.breakpoints.mediaQueries.medium]: {
                  paddingTop: theme.sizing.scale2400,
                  paddingBottom: theme.sizing.scale2400,
                },
                [theme.breakpoints.mediaQueries.small]: {
                  paddingTop: theme.sizing.scale1200,
                  paddingBottom: theme.sizing.scale1200,
                },
              })}
            >
              <DisplayLarge
                $style={{
                  textAlign: "center",
                  [theme.breakpoints.mediaQueries.medium]: {
                    textAlign: "center",
                    ...theme.typography.DisplayMedium,
                    fontWeight: "bold",
                  },
                  [theme.breakpoints.mediaQueries.small]: {
                    ...theme.typography.DisplayXSmall,
                  },
                }}
              >
                <FormattedContentItem scope="Home" accessor="contact.lead" />
              </DisplayLarge>
              <div
                className={css({
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: theme.sizing.scale1400,
                  marginBottom: theme.sizing.scale1400,
                  flexWrap: "wrap",
                  [theme.breakpoints.mediaQueries.small]: {
                    flexDirection: "column",
                  },
                })}
              >
                <Button
                  kind={ContentObject.Home.contact.buttons[0].kind}
                  label={ContentObject.Home.contact.buttons[0].label}
                  onClick={() =>
                    navigate(ContentObject.Home.contact.buttons[0].to)
                  }
                >
                  <ContentItem
                    scope="Home"
                    accessor={`contact.buttons.0.title`}
                  />
                </Button>
                <div
                  className={css({
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "46px",
                    marginLeft: theme.sizing.scale1000,
                    marginRight: theme.sizing.scale1000,
                  })}
                >
                  or
                </div>
                <Button
                  kind={ContentObject.Home.contact.buttons[1].kind}
                  label={ContentObject.Home.contact.buttons[1].label}
                  onClick={() =>
                    navigate(ContentObject.Home.contact.buttons[1].to)
                  }
                >
                  <ContentItem
                    scope="Home"
                    accessor={`contact.buttons.1.title`}
                  />
                </Button>
              </div>
              <ParagraphLarge
                $style={{
                  marginTop: theme.sizing.scale1400,
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <FormattedContentItem scope="Home" accessor="contact.subtext" />
              </ParagraphLarge>
            </div>
          </Cell>
          <Cell skip={2} span={4}>
            <div
              className={css({
                position: "relative",
                width: "100%",
                height: "100%",
                marginTop: "-420px",
              })}
            >
              <img
                alt="Dot grid background"
                className={css({
                  position: "absolute",
                  top: "0%",
                  left: "0%",
                  zIndex: "0",
                  pointerEvents: "none",
                })}
                src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
              />
            </div>
          </Cell>
        </Grid>
      </article>
      {isSticked && (
        <div
          className={css({
            position: "relative",
            height: "100vh",
            width: "100vw",
          })}
        ></div>
      )}
    </>
  );
};

export default Contact;
