import Atende from "./atende.svg";
import EdataPolska from "./edata-polska.svg";
import EDF from "./edf.svg";
import EnergaOperator from "./energa.svg";
import IPS from "./ips.svg";
import KantarPolska from "./kantar.svg";
import SputnikSoftware from "./sputnik.svg";

export default {
  Atende,
  EdataPolska,
  EDF,
  EnergaOperator,
  IPS,
  KantarPolska,
  SputnikSoftware,
};
