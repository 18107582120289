import React, { useState, useEffect } from "react";

import { Grid, Cell, Button } from "../../components/ui";
import {
  ContentObject,
  ContentItem,
  FormattedContentItem,
} from "../../components/content";

import { useStyletron } from "baseui";
import {
  LabelXSmall,
  LabelSmall,
  HeadingMedium,
  ParagraphLarge,
  LabelMedium,
} from "baseui/typography";

import stars from "../../assets/images/stars-purple.svg";
import clutchLogo from "../../assets/images/clutch-logo.svg";
import clutchLogoWhite from "../../assets/images/clutch-logo-white.svg";
import chevronLeft from "../../assets/images/chevron-left-gray.svg";

import { Spring } from "react-spring/renderprops";

import { useTheme } from "../../contexts/theme";

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const Testimonials = () => {
  const [css, theme] = useStyletron();
  const [index, setIndex] = useState(0);
  const { useDarkTheme } = useTheme();

  useEffect(() => shuffle(ContentObject.Home.testimonials.items), []);

  const itemsCount = ContentObject.Home.testimonials.items.length;

  return (
    <article data-csweb="home-testimonials">
      <Spring
        key={index}
        config={{ tension: 120, friction: 14, duration: 200 }}
        from={{ opacity: 0, left: -100 }}
        to={{ opacity: 1, left: 0 }}
      >
        {({ left, ...style }) => {
          return (
            <div
              className={css({
                position: "relative",
                marginTop: theme.sizing.scale3200,
                marginBottom: theme.sizing.scale600,
                transform: `translateX(${left}px)`,
                ":hover [data-csweb=img-photo]": {
                  filter: "grayscale(0)",
                  opacity: "1",
                },
                ":hover [data-csweb=box-gradient]": {
                  top: "20%",
                },
                ":hover p": {
                  marginTop: theme.sizing.scale1000,
                  paddingBottom: theme.sizing.scale1000,
                },
                ":hover [data-csweb=testimonial-footer]": {
                  paddingBottom: theme.sizing.scale0,
                },
                ":hover [data-csweb=bg-graybox]": {
                  marginTop: "-200px",
                },
                ...style,
              })}
            >
              <Grid>
                <Cell span={[0, 0, 1]} align="flex-end">
                  <LabelSmall
                    $style={{
                      marginTop: theme.sizing.scale50,
                      marginBottom: theme.sizing.scale500,
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      transitionProperty: "all",
                      transitionDuration: "200ms",
                      transitionTimingFunction: "ease-in-out",
                      textAlign: "center",
                      [theme.breakpoints.mediaQueries.medium]: {
                        display: "none",
                      },
                    }}
                  >
                    {String(index + 1).padStart(2, "0")}/
                    {String(
                      ContentObject.Home.testimonials.items.length
                    ).padStart(2, "0")}
                  </LabelSmall>
                </Cell>
                <Cell skip={[0, 0, 1]} span={[4, 8, 8]} align="center">
                  <div
                    className={css({
                      position: "relative",
                      zIndex: "4",
                    })}
                  >
                    <img
                      alt="Stars"
                      src={stars}
                      height="18px"
                      className={css({
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      })}
                    />
                    <HeadingMedium
                      $as="div"
                      $style={{
                        marginTop: theme.sizing.scale1600,
                        marginBottom: theme.sizing.scale200,
                      }}
                    >
                      <ContentItem
                        scope="Home"
                        accessor={`testimonials.items.${index}.title`}
                      />
                    </HeadingMedium>
                    <ParagraphLarge
                      $style={{
                        marginTop: theme.sizing.scale900,
                        paddingBottom: theme.sizing.scale900,
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                      }}
                    >
                      <FormattedContentItem
                        scope="Home"
                        accessor={`testimonials.items.${index}.text`}
                      />
                    </ParagraphLarge>
                    <div
                      data-csweb="testimonial-footer"
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        paddingBottom: theme.sizing.scale650,
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                        [theme.breakpoints.mediaQueries.small]: {
                          flexDirection: "column",
                          paddingRight: theme.sizing.scale400,
                        },
                      })}
                    >
                      <div
                        className={css({
                          [theme.breakpoints.mediaQueries.small]: {
                            order: "1",
                            marginTop: theme.sizing.scale800,
                            marginBottom: theme.sizing.scale600,
                          },
                        })}
                      >
                        <a
                          href={
                            ContentObject.Home.testimonials.items[index].url
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt="Clutch"
                            src={useDarkTheme ? clutchLogoWhite : clutchLogo}
                            height="20px"
                          />
                          <LabelSmall
                            data-csweb="typo-label"
                            $style={{
                              marginLeft: theme.sizing.scale500,
                              textTransform: "uppercase",
                              fontWeight: "400",
                              transitionProperty: "all",
                              transitionDuration: "200ms",
                              transitionTimingFunction: "ease-in-out",
                              display: "inline-block",
                              letterSpacing: "1.5px",
                              [theme.breakpoints.mediaQueries.small]: {
                                display: "none",
                              },
                            }}
                          >
                            <FormattedContentItem
                              scope="Home"
                              accessor={`testimonials.readFullReview`}
                            />
                          </LabelSmall>
                        </a>
                      </div>
                      <div
                        className={css({
                          [theme.breakpoints.mediaQueries.small]: {
                            alignSelf: "flex-end",
                          },
                        })}
                      >
                        <LabelMedium
                          color="primary"
                          $style={{
                            letterSpacing: "1.5px",
                            fontWeight: "700",
                            textAlign: "right",
                          }}
                        >
                          <ContentItem
                            scope="Home"
                            accessor={`testimonials.items.${index}.name`}
                          />
                        </LabelMedium>
                        <LabelSmall
                          data-csweb="typo-label"
                          $style={{
                            marginTop: theme.sizing.scale200,
                            textTransform: "uppercase",
                            letterSpacing: "2px",
                            fontWeight: "400",
                            transitionProperty: "all",
                            transitionDuration: "200ms",
                            transitionTimingFunction: "ease-in-out",
                            textAlign: "right",
                          }}
                        >
                          <ContentItem
                            scope="Home"
                            accessor={`testimonials.items.${index}.job`}
                          />
                        </LabelSmall>
                      </div>
                    </div>
                  </div>
                </Cell>
                <Cell span={[0, 0, 1]} align="center">
                  <LabelXSmall
                    color="blue50"
                    $style={{
                      position: "absolute",
                      transform: "rotate(90deg)",
                      right: "12px",
                      top: "50%",
                      textTransform: "uppercase",
                      letterSpacing: "8px",
                      [theme.breakpoints.mediaQueries.medium]: {
                        display: "none",
                      },
                    }}
                  >
                    <ContentItem scope="Home" accessor="testimonials.label" />
                  </LabelXSmall>
                </Cell>
                <Cell span={[4, 6, 7]}>
                  <div
                    data-csweb="bg-graybox"
                    className={css({
                      position: "relative",
                      transitionProperty: "all",
                      transitionDuration: "200ms",
                      transitionTimingFunction: "ease-in-out",
                      width: "100%",
                      height: "260px",
                      marginTop: "-150px",
                      marginBottom: "-110px",
                      zIndex: "0",
                      opacity: "0.3",
                      backgroundColor: theme.colors.backgroundTertiary,
                      [theme.breakpoints.mediaQueries.medium]: {
                        marginTop: "-160px",
                      },
                    })}
                  ></div>
                </Cell>
              </Grid>
            </div>
          );
        }}
      </Spring>
      <div
        className={css({
          position: "relative",
          marginBottom: theme.sizing.scale4800,
          [theme.breakpoints.mediaQueries.large]: {
            marginBottom: theme.sizing.scale1200,
          },
        })}
      >
        <Grid>
          <Cell skip={[0, 0, 10]} span={[4, 8, 2]}>
            <div
              className={css({
                display: "flex",
                justifyContent: "space-between",
                float: "right",
                width: "100%",
                maxWidth: "92px",
                [theme.breakpoints.mediaQueries.small]: {
                  marginRight: "10px",
                },
              })}
            >
              <Button
                isControl
                kind="circle"
                direction="left"
                onClick={() =>
                  setIndex(index - 1 < 0 ? itemsCount - 1 : index - 1)
                }
              >
                <img alt="More" src={chevronLeft} height="14px" />
              </Button>
              <Button
                isControl
                kind="circle"
                onClick={() =>
                  setIndex(index + 1 > itemsCount - 1 ? 0 : index + 1)
                }
              >
                <img
                  alt="More"
                  src={chevronLeft}
                  className={css({ transform: "rotate(180deg)" })}
                  height="14px"
                />
              </Button>
            </div>
          </Cell>
        </Grid>
      </div>
    </article>
  );
};

export default Testimonials;
