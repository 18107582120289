import React from "react";

import { Grid, Cell } from "../../components/ui";

import { useStyletron } from "baseui";

import Logos from "../../assets/images/partners";

import { ContentObject } from "../../components/content";

const Partners = () => {
  const [css, theme] = useStyletron();

  return (
    <article data-csweb="home-partners">
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              paddingTop: theme.sizing.scale1200,
              paddingBottom: theme.sizing.scale1200,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              [theme.breakpoints.mediaQueries.medium]: {
                paddingTop: theme.sizing.scale300,
                paddingBottom: theme.sizing.scale300,
                flexWrap: "wrap",
                justifyContent: "center",
              },
            })}
          >
            {ContentObject.Home.partners.map((item, index) => {
              return (
                <img
                  key={index}
                  src={Logos[item.icon]}
                  alt={item.name}
                  className={css({
                    maxWidth: "110px",
                    maxHeight: "32px",
                    [theme.breakpoints.mediaQueries.medium]: {
                      marginLeft: theme.sizing.scale500,
                      marginRight: theme.sizing.scale500,
                      marginTop: theme.sizing.scale400,
                      marginBottom: theme.sizing.scale400,
                      maxWidth: "80px",
                      maxHeight: "22px",
                    },
                  })}
                />
              );
            })}
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Partners;
