import React, { useEffect, useState } from "react";

import { useStyletron } from "baseui";

import shineBg from "../../assets/images/shine-bg.svg";
import logoWhite from "../../assets/images/logo-white.svg";

const Shine = () => {
  const [css, theme] = useStyletron();
  const [isSticked, setIsSticked] = useState(false);

  let Motus;

  if (typeof window !== "undefined" && window) Motus = require("motus");

  const onScroll = () => {
    if (
      window.scrollY >
        document.querySelector("[data-csweb=home-shine]").offsetTop &&
      window.scrollY < document.body.scrollHeight
    )
      setIsSticked(true);
    else setIsSticked(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    if (typeof window !== "undefined") {
      if (Motus && Motus.default && Motus.default.Animation) {
        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=home-shine]").offsetTop + 100,
            endPoint: document.querySelector("[data-csweb=home-contact]")
              .offsetTop,
            $el: document.querySelector("[data-csweb=shine-wrapper]"),
            keyframes: [
              { scale: [1], blur: [`0px`] },
              { scale: [20], blur: [`1px`] },
            ],
          })
        );

        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=home-shine]").offsetTop + 100,
            endPoint: document.querySelector("[data-csweb=home-contact]")
              .offsetTop,
            $el: document.querySelector("[data-csweb=shine-corner-lt]"),
            keyframes: [
              { borderBottomRightRadius: 200 },
              { borderBottomRightRadius: 200 },
            ],
          })
        );

        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=home-shine]").offsetTop + 100,
            endPoint: document.querySelector("[data-csweb=home-contact]")
              .offsetTop,
            $el: document.querySelector("[data-csweb=shine-corner-rt]"),
            keyframes: [
              { borderBottomLeftRadius: 200 },
              { borderBottomLeftRadius: 200 },
            ],
          })
        );

        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=home-shine]").offsetTop + 100,
            endPoint: document.querySelector("[data-csweb=home-contact]")
              .offsetTop,
            $el: document.querySelector("[data-csweb=shine-corner-rb]"),
            keyframes: [
              { borderTopLeftRadius: 200 },
              { borderTopLeftRadius: 200 },
            ],
          })
        );

        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=home-shine]").offsetTop + 100,
            endPoint: document.querySelector("[data-csweb=home-contact]")
              .offsetTop,
            $el: document.querySelector("[data-csweb=shine-corner-lb]"),
            keyframes: [
              { borderTopRightRadius: 200 },
              { borderTopRightRadius: 200 },
            ],
          })
        );
      }
    }
  }, []);

  useEffect(
    () => () => {
      Motus.default.clearAnimations();

      window.removeEventListener("scroll", onScroll);
    },
    []
  );

  return (
    <article
      data-csweb="home-shine"
      className={css({
        height: "500vh",
        marginBottom: "-100vh",
        overflow: "hidden",
        position: "relative",
        [theme.breakpoints.mediaQueries.large]: {
          height: "0vh",
          display: "none",
          marginBottom: "0vh",
        },
      })}
    >
      <div
        data-csweb="shine-wrapper"
        className={css({
          zIndex: "30",
          top: isSticked ? "0px" : "unset",
          width: "100vw",
          height: "100vh",
          position: isSticked ? "fixed" : "absolute",
          overflow: "hidden",
          pointerEvents: "none",
        })}
      >
        <img
          alt="Shine background"
          src={shineBg}
          className={css({
            zIndex: "31",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
            width: "100%",
          })}
        />
        <img
          alt="Codeshine"
          src={logoWhite}
          className={css({
            zIndex: "31",
            bottom: "40px",
            right: "80px",
            position: "absolute",
            height: "30px",
          })}
        />
        <div
          className={css({
            zIndex: "31",
            bottom: "40px",
            left: "80px",
            position: "absolute",
            height: "30px",
            fontSize: "14px",
            fontWeight: "400",
            color: "white",
          })}
        >
          return{" "}
          <span className={css({ color: "#00DCE6" })}>
            &#x3C;AwesomeProduct&#x3E;project.
          </span>
          <span className={css({ color: "#DC1C9A" })}>product</span>;
        </div>
        <div
          data-csweb="shine-corner-lt"
          className={css({
            zIndex: "30",
            top: "0px",
            left: "0px",
            width: "50vw",
            height: "50vh",
            position: "absolute",
            borderBottomRightRadius: "200px",
            backgroundColor: theme.colors.primary,
          })}
        ></div>
        <div
          data-csweb="shine-corner-rt"
          className={css({
            zIndex: "30",
            top: "0px",
            right: "0px",
            width: "50vw",
            height: "50vh",
            position: "absolute",
            borderBottomLeftRadius: "200px",
            backgroundColor: theme.colors.primary,
          })}
        ></div>
        <div
          data-csweb="shine-corner-rb"
          className={css({
            zIndex: "30",
            bottom: "0px",
            right: "0px",
            width: "50vw",
            height: "50vh",
            position: "absolute",
            borderTopLeftRadius: "200px",
            backgroundColor: theme.colors.primary,
          })}
        ></div>
        <div
          data-csweb="shine-corner-lb"
          className={css({
            zIndex: "30",
            bottom: "0px",
            left: "0px",
            width: "50vw",
            height: "50vh",
            position: "absolute",
            borderTopRightRadius: "200px",
            backgroundColor: theme.colors.primary,
          })}
        ></div>
      </div>
    </article>
  );
};

export default Shine;
