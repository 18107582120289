import React, { useState, useRef, useEffect } from "react";
import { navigate } from "gatsby";

import { Grid, Cell, Button, Img } from "../../components/ui";
import {
  ContentObject,
  ContentItem,
  FormattedContentItem,
} from "../../components/content";

import { useStyletron } from "baseui";
import {
  LabelXSmall,
  LabelSmall,
  HeadingXLarge,
  HeadingMedium,
  ParagraphLarge,
} from "baseui/typography";

import { AspectRatioBox, AspectRatioBoxBody } from "baseui/aspect-ratio-box";

import dotGrid from "../../assets/images/dot-grid-light.svg";
import bgPath from "../../assets/images/bg-path.svg";

import Logo from "../../assets/images/case-studies/logo";
import Masks from "../../assets/images/masks";

const CaseStudies = () => {
  const [css, theme] = useStyletron();

  const maskRef = useRef(null);
  const [maskHeight, setMaskHeight] = useState();

  useEffect(() => {
    if (maskRef && maskRef.current && maskRef.current.complete)
      setMaskHeight(maskRef.current.height);
  }, []);

  return (
    <article
      data-csweb="home-case-studies"
      className={css({ overflow: "hidden" })}
    >
      <div
        className={css({
          backgroundColor: theme.colors.primary,
          position: "relative",
          ":hover [data-csweb=bg-path]": {
            transform: "scaleY(1.6)",
          },
        })}
      >
        <img
          alt="Dot grid background"
          className={css({
            position: "absolute",
            bottom: "20%",
            left: "10%",
            zIndex: "0",
            pointerEvents: "none",
          })}
          src={dotGrid}
        />
        <img
          alt="Single path background"
          data-csweb="bg-path"
          className={css({
            position: "absolute",
            top: "25%",
            left: "-5%",
            right: "-10%",
            zIndex: "1",
            pointerEvents: "none",
            transitionProperty: "all",
            transitionDuration: "400ms",
            transitionTimingFunction: "ease-in-out",
          })}
          src={bgPath}
        />
        <div
          className={css({
            position: "relative",
            zIndex: "4",
            overflow: "hidden",
            [theme.breakpoints.mediaQueries.medium]: {
              paddingBottom: theme.sizing.scale1600,
            },
          })}
        >
          <Grid>
            <Cell skip={[0, 0, 1]} span={[4, 4, 5]}>
              <LabelSmall
                $style={{
                  marginTop: theme.sizing.scale1600,
                  paddingBottom: theme.sizing.scale800,
                  color: theme.colors.white,
                  textTransform: "uppercase",
                  letterSpacing: "6px",
                  fontWeight: "700",
                  [theme.breakpoints.mediaQueries.medium]: {
                    marginTop: theme.sizing.scale900,
                    paddingBottom: theme.sizing.scale200,
                  },
                }}
              >
                <ContentItem scope="Home" accessor="caseStudies.label" />
              </LabelSmall>

              <HeadingXLarge
                $style={{
                  marginTop: theme.sizing.scale3200,
                  marginBottom: theme.sizing.scale3200,
                  paddingBottom: theme.sizing.scale800,
                  color: theme.colors.white,
                  fontWeight: "600",
                  [theme.breakpoints.mediaQueries.medium]: {
                    marginTop: theme.sizing.scale1000,
                    marginBottom: theme.sizing.scale800,
                    ...theme.typography.DisplayMedium,
                    fontWeight: "bold",
                  },
                  [theme.breakpoints.mediaQueries.small]: {
                    ...theme.typography.DisplaySmall,
                  },
                }}
              >
                <FormattedContentItem
                  scope="Home"
                  accessor="caseStudies.items.0.title"
                />
              </HeadingXLarge>

              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-end",
                  marginBottom: theme.sizing.scale1600,
                  flexWrap: "nowrap",
                  [theme.breakpoints.mediaQueries.small]: {
                    justifyContent: "space-between",
                  },
                })}
              >
                {ContentObject.Home.caseStudies.items[0].logo && (
                  <a
                    className={css({
                      textDecoration: "none",
                      color: "white",
                      transitionProperty: "all",
                      transitionDuration: "200ms",
                      transitionTimingFunction: "ease-in-out",
                      ":hover": {
                        opacity: 0.8,
                      },
                    })}
                    href={ContentObject.Home.caseStudies.items[0].url}
                    title={ContentObject.Home.caseStudies.items[0].name}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Logo[ContentObject.Home.caseStudies.items[0].logo]}
                      alt={ContentObject.Home.caseStudies.items[0].name}
                      height="38px"
                      className={css({
                        [theme.breakpoints.mediaQueries.small]: {
                          marginRight: theme.sizing.scale0,
                          marginTop: theme.sizing.scale600,
                          height: "26px",
                        },
                      })}
                    />
                  </a>
                )}
                {ContentObject.Home.caseStudies.items[0].partnership && (
                  <>
                    <LabelXSmall
                      $style={{
                        fontSize: "10px",
                        textTransform: "uppercase",
                        fontWeight: "700",
                        letterSpacing: "2px",
                        marginRight: theme.sizing.scale750,
                        marginLeft: theme.sizing.scale1400,
                        lineHeight: "12px",
                        color: theme.colors.white,
                        textAlign: "right",
                        [theme.breakpoints.mediaQueries.small]: {
                          marginRight: theme.sizing.scale200,
                          marginLeft: theme.sizing.scale700,
                        },
                      }}
                    >
                      {
                        ContentObject.Home.caseStudies.items[0].partnership
                          .label
                      }
                    </LabelXSmall>
                    <img
                      alt={
                        ContentObject.Home.caseStudies.items[0].partnership
                          .label
                      }
                      src={
                        Logo[
                          ContentObject.Home.caseStudies.items[0].partnership
                            .logo
                        ]
                      }
                      height="20px"
                      className={css({
                        [theme.breakpoints.mediaQueries.small]: {
                          marginLeft: theme.sizing.scale0,
                          height: "18px",
                        },
                      })}
                    />
                  </>
                )}
              </div>
            </Cell>
            <Cell span={[4, 4, 6]}>
              <div
                className={css({
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  bottom: "0px",
                  [theme.breakpoints.mediaQueries.small]: {
                    display: "none",
                  },
                })}
              >
                <div
                  className={css({
                    position: "absolute",
                    width: maskHeight
                      ? `${Masks[
                          ContentObject.Home.caseStudies.items[0].heroMask
                        ].widthMod * maskHeight}px`
                      : `auto`,
                    top: theme.sizing.scale1200,
                    ...(Masks[ContentObject.Home.caseStudies.items[0].heroMask]
                      .align === "left"
                      ? { left: "-60px" }
                      : { right: "50%", transform: "translateX(50%)" }),
                    overflow: "hidden",
                    pointerEvents: "none",
                    zIndex: "4",
                    bottom: Masks[
                      ContentObject.Home.caseStudies.items[0].heroMask
                    ].offset
                      ? Masks[ContentObject.Home.caseStudies.items[0].heroMask]
                          .offset.bottom
                      : "-50px",
                    [theme.breakpoints.mediaQueries.large]: {
                      ...(Masks[
                        ContentObject.Home.caseStudies.items[0].heroMask
                      ].align === "left"
                        ? { left: "-80px" }
                        : { left: "10px", right: "unset" }),
                    },
                  })}
                >
                  <img
                    alt="Device mask"
                    ref={maskRef}
                    onLoad={() => setMaskHeight(maskRef.current.height)}
                    src={
                      Masks[ContentObject.Home.caseStudies.items[0].heroMask][
                        ContentObject.Home.caseStudies.items[0].heroMaskVariant
                      ]
                    }
                    height="100%"
                    className={css({
                      zIndex: "6",
                      position: "relative",
                      display: "block",
                    })}
                  />
                  <div
                    className={css({
                      position: "absolute",
                      overflow: "hidden",
                      borderTopLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                      borderBottomRightRadius: "15px",
                      borderBottomLeftRadius: "15px",
                      zIndex: "5",
                      top:
                        Masks[ContentObject.Home.caseStudies.items[0].heroMask]
                          .inset.top,
                      left:
                        Masks[ContentObject.Home.caseStudies.items[0].heroMask]
                          .inset.left,
                      right:
                        Masks[ContentObject.Home.caseStudies.items[0].heroMask]
                          .inset.right,
                      bottom:
                        Masks[ContentObject.Home.caseStudies.items[0].heroMask]
                          .inset.bottom,
                    })}
                  >
                    <AspectRatioBox
                      aspectRatio={
                        Masks[ContentObject.Home.caseStudies.items[0].heroMask]
                          .ratio
                      }
                      overflow="hidden"
                    >
                      <AspectRatioBoxBody
                        as={() => (
                          <Img
                            src={`case-studies/${ContentObject.Home.caseStudies.items[0].slug}/${ContentObject.Home.caseStudies.items[0].heroMaskImage}`}
                            objectPosition={
                              Masks[
                                ContentObject.Home.caseStudies.items[0].heroMask
                              ].objectPosition || "left"
                            }
                          />
                        )}
                        maxHeight="100%"
                        $style={{
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                          borderBottomRightRadius: "15px",
                          borderBottomLeftRadius: "15px",
                          transform: "translateY(-50%)",
                          top: "50%",
                        }}
                      />
                    </AspectRatioBox>
                  </div>
                </div>
              </div>
            </Cell>
          </Grid>
        </div>
      </div>

      <div
        className={css({
          marginTop: "-80px",
        })}
      >
        <Grid>
          <Cell skip={[0, 4, 8]} span={[4, 4, 4]}>
            <div
              className={css({
                position: "relative",
                zIndex: "6",
                width: "100%",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                background:
                  "linear-gradient(90deg, #2848df 0%, rgba(60,0,220,1) 100%)",
                transitionProperty: "all",
                transitionDuration: "200ms",
                transitionTimingFunction: "ease-in-out",
                "::before": {
                  pointerEvents: "none",
                  position: "absolute",
                  content: "''",
                  left: "0",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  background:
                    "linear-gradient(90deg, rgba(60,0,220,1) 0%, #2848df 100%)",
                  transitionProperty: "all",
                  transitionDuration: "200ms",
                  transitionTimingFunction: "ease-in-out",
                  opacity: "0",
                  zIndex: "1",
                },
                ":hover::before": {
                  opacity: "1",
                },
                ":hover button": {
                  backgroundColor: "transparent",
                  borderTopWidth: "2px",
                  borderRightWidth: "2px",
                  borderBottomWidth: "2px",
                  borderLeftWidth: "2px",
                  borderTopStyle: "solid",
                  borderRightStyle: "solid",
                  borderBottomStyle: "solid",
                  borderLeftStyle: "solid",
                  borderTopColor: "white",
                  borderRightColor: "white",
                  borderBottomColor: "white",
                  borderLeftColor: "white",
                  zIndex: "2",
                },
                ":hover button span": {
                  transform: "translateY(30px)",
                },
                ":hover button span::after": {
                  transform: "translateY(10px)",
                },
                ":hover button em": {
                  opacity: "1",
                  transform: "scale(1)",
                },
              })}
            >
              <Button
                kind={ContentObject.Home.caseStudies.button.kind}
                label={ContentObject.Home.caseStudies.button.label}
                color="white"
                onClick={() =>
                  navigate(ContentObject.Home.caseStudies.button.to)
                }
              >
                <ContentItem scope="Home" accessor="caseStudies.button.title" />
              </Button>
            </div>
          </Cell>
        </Grid>
      </div>
      <div
        className={css({
          marginTop: "0px",
        })}
      >
        <Grid>
          <Cell skip={[2, 4, 6]} span={[2, 4, 6]}>
            <div
              className={css({
                position: "relative",
              })}
            >
              <div
                className={css({
                  position: "absolute",
                  zIndex: "0",
                  width: "100%",
                  height: "440px",
                  opacity: "0.3",
                  backgroundColor: theme.colors.backgroundSecondary,
                })}
              ></div>
            </div>
          </Cell>
        </Grid>
      </div>
      <div className={css({ position: "relative" })}>
        <Grid>
          <Cell skip={[0, 0, 1]} span={[4, 6, 7]}>
            <HeadingMedium
              $as="div"
              $style={{
                marginTop: theme.sizing.scale1200,
                paddingTop: theme.sizing.scale900,
                marginBottom: theme.sizing.scale800,
                [theme.breakpoints.mediaQueries.medium]: {
                  marginTop: theme.sizing.scale0,
                  textAlign: "center",
                },
              }}
            >
              <ContentItem scope="Home" accessor="caseStudies.items.0.name" />
            </HeadingMedium>
            <ParagraphLarge
              $style={{
                marginTop: theme.sizing.scale200,
                paddingBottom: theme.sizing.scale900,
                marginBottom: theme.sizing.scale1200,
                [theme.breakpoints.mediaQueries.medium]: {
                  marginBottom: theme.sizing.scale300,
                  textAlign: "center",
                },
              }}
            >
              <ContentItem
                scope="Home"
                accessor="caseStudies.items.0.caption"
              />
            </ParagraphLarge>
          </Cell>
          <Cell skip={[0, 0, 1]} span={[4, 2, 2]}>
            <LabelSmall
              $style={{
                marginTop: theme.sizing.scale1600,
                marginBottom: theme.sizing.scale600,
                paddingTop: theme.sizing.scale900,
                textTransform: "uppercase",
                letterSpacing: "6px",
                [theme.breakpoints.mediaQueries.medium]: {
                  marginTop: theme.sizing.scale0,
                  paddingTop: theme.sizing.scale0,
                  marginBottom: theme.sizing.scale200,
                  textAlign: "center",
                },
              }}
            >
              Visit Online
            </LabelSmall>

            <Button
              kind="link"
              $as="a"
              href={ContentObject.Home.caseStudies.items[0].url}
              title={ContentObject.Home.caseStudies.items[0].name}
              target="_blank"
              rel="noopener norefferer"
              className={css({
                [theme.breakpoints.mediaQueries.medium]: {
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  maxWidth: `14ch`,
                },
              })}
            >
              <ContentItem scope="Home" accessor="caseStudies.items.0.link" />
            </Button>
          </Cell>
        </Grid>
      </div>
    </article>
  );
};

export default CaseStudies;
