import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Lead from "./home-lead";
import Partners from "./home-partners";
import Services from "./home-services";
import CaseStudies from "./home-case-studies";
import Testimonials from "./home-testimonials";
import Shine from "./home-shine";
import Contact from "./home-contact";

const Home = () => (
  <>
    <SEO
      title={ContentObject.Home.meta.title}
      description={ContentObject.Home.meta.description}
    />

    <Layout header={{ position: "absolute" }} title="Home">
      <section data-csweb="home">
        <Lead />
        <Partners />
        <Services />
        <CaseStudies />
        <Testimonials />
        <Shine />
        <Contact />
      </section>
    </Layout>
  </>
);

export default Home;
