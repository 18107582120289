import React from "react";
import { navigate } from "gatsby";

import { Grid, Cell, Button } from "../../components/ui";
import {
  FormattedContentItem,
  ContentObject,
  ContentItem,
} from "../../components/content";

import { DisplayLarge, LabelXSmall, ParagraphMedium } from "baseui/typography";
import { useStyletron } from "baseui";

import logoShine from "../../assets/images/logo-shine.svg";
import dotGrid from "../../assets/images/dot-grid.svg";
import dotGridWhite from "../../assets/images/dot-grid-white.svg";
import clutchBadge from "../../assets/images/clutch-badge.svg";

import Social from "../../assets/images/social";
import { useTheme } from "../../contexts/theme";

const Lead = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <article
      data-csweb="home-lead"
      className={css({
        paddingTop: theme.sizing.scale4800,
        [theme.breakpoints.mediaQueries.large]: {
          paddingTop: theme.sizing.scale3200,
        },
        [theme.breakpoints.mediaQueries.medium]: {
          paddingTop: theme.sizing.scale1600,
        },
      })}
    >
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 5, 6]}>
          <div
            className={css({
              paddingTop: theme.sizing.scale2400,
              paddingBottom: theme.sizing.scale2400,
              overflow: "hidden",
              [theme.breakpoints.mediaQueries.large]: {
                paddingTop: theme.sizing.scale1600,
              },
            })}
          >
            <DisplayLarge
              $style={{
                [theme.breakpoints.mediaQueries.medium]: {
                  textAlign: "center",
                  ...theme.typography.DisplayMedium,
                  fontWeight: "bold",
                },
                [theme.breakpoints.mediaQueries.small]: {
                  ...theme.typography.DisplayXSmall,
                },
              }}
            >
              <FormattedContentItem scope="Home" accessor="lead.title" />
            </DisplayLarge>
            <div
              className={css({
                marginTop: theme.sizing.scale900,
                marginBottom: theme.sizing.scale800,
                display: "flex",
                [theme.breakpoints.mediaQueries.medium]: {
                  display: "none",
                },
              })}
            >
              {ContentObject.Home.lead.buttons.map((item, index) => (
                <Button
                  key={index}
                  kind={item.kind}
                  label={item.label}
                  className={css({
                    marginRight: theme.sizing.scale500,
                  })}
                  onClick={() => navigate(item.to)}
                >
                  {item.title}
                </Button>
              ))}
            </div>

            <ParagraphMedium
              $style={{
                [theme.breakpoints.mediaQueries.medium]: {
                  display: "none",
                },
              }}
            >
              <span
                className={css({ display: "inline-block", maxWidth: "40ch" })}
              >
                <FormattedContentItem scope="Home" accessor="lead.subtitle" />
              </span>
            </ParagraphMedium>
          </div>
        </Cell>
        <Cell span={[4, 3, 3]} skip={[0, 0, 1]}>
          <div
            className={css({
              position: "relative",
              width: "100%",
              height: "100%",
              [theme.breakpoints.mediaQueries.medium]: {
                marginTop: "5vw",
              },
            })}
          >
            <div
              className={css({
                backgroundColor: theme.colors.backgroundSecondary,
                position: "absolute",
                right: "0px",
                bottom: `0px`,
                top: `0px`,
                borderBottomRightRadius: "62px",
                width: "100%",
                [theme.breakpoints.mediaQueries.medium]: {
                  paddingTop: "15vw",
                  paddingBottom: theme.sizing.scale1400,
                  position: "relative",
                  textAlign: "center",
                  borderBottomRightRadius: "0px",
                },
              })}
            >
              <LabelXSmall
                $style={{
                  color: theme.colors.leadSideLabelText,
                  position: "absolute",
                  transform: "rotate(90deg)",
                  right: "12px",
                  top: "50%",
                  textTransform: "uppercase",
                  letterSpacing: "8px",
                  [theme.breakpoints.mediaQueries.medium]: {
                    display: "none",
                  },
                }}
              >
                <ContentItem scope="Home" accessor="lead.sideLabel" />
              </LabelXSmall>

              <img
                alt="Shine"
                className={css({
                  position: "absolute",
                  transform: "translateX(50%) translateY(-50%)",
                  right: "100%",
                  height: "25vw",
                  maxHeight: "320px",
                  top: "50%",
                  zIndex: "20",
                  pointerEvents: "none",
                  [theme.breakpoints.mediaQueries.medium]: {
                    right: "50%",
                    top: "0px",
                  },
                })}
                src={logoShine}
              />

              <div
                className={css({
                  marginTop: theme.sizing.scale900,
                  marginBottom: theme.sizing.scale800,
                  display: "none",
                  [theme.breakpoints.mediaQueries.medium]: {
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  },
                })}
              >
                {ContentObject.Home.lead.buttons.map((item, index) => (
                  <Button
                    key={index}
                    kind={item.kind}
                    label={item.label}
                    className={css({
                      ...(index + 1 !==
                        ContentObject.Home.lead.buttons.length && {
                        marginRight: theme.sizing.scale500,
                      }),
                    })}
                    onClick={() => navigate(item.to)}
                  >
                    {item.title}
                  </Button>
                ))}
              </div>

              <ParagraphMedium
                $style={{
                  display: "none",
                  [theme.breakpoints.mediaQueries.medium]: {
                    display: "block",
                  },
                }}
              >
                <span
                  className={css({ display: "inline-block", maxWidth: "40ch" })}
                >
                  <FormattedContentItem scope="Home" accessor="lead.subtitle" />
                </span>
              </ParagraphMedium>

              <img
                alt="Dot grid background"
                className={css({
                  position: "absolute",
                  bottom: "10%",
                  right: "-100%",
                  zIndex: "15",
                  pointerEvents: "none",
                })}
                src={useDarkTheme ? dotGridWhite : dotGrid}
              />
              <img
                alt="Dot grid background"
                className={css({
                  position: "absolute",
                  bottom: "10%",
                  right: "360%",
                  zIndex: "15",
                  pointerEvents: "none",
                })}
                src={useDarkTheme ? dotGridWhite : dotGrid}
              />
            </div>
          </div>
        </Cell>
        <Cell span={[4, 8, 10]} skip={[0, 0, 1]}>
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              flexWrap: "nowrap",
              paddingTop: theme.sizing.scale1600,
              paddingBottom: theme.sizing.scale1600,
              alignItems: "center",
              [theme.breakpoints.mediaQueries.medium]: {
                paddingTop: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale600,
              },
              [theme.breakpoints.mediaQueries.small]: {
                paddingTop: theme.sizing.scale0,
                paddingBottom: theme.sizing.scale600,
                marginTop: `-${theme.sizing.scale1400}`,
                justifyContent: "center",
              },
            })}
          >
            <a
              href={ContentObject.Shared.linkedInLink}
              target="_blank"
              rel="noopener noreferrer"
              className={css({
                [theme.breakpoints.mediaQueries.small]: {
                  display: "none",
                },
              })}
            >
              <Button kind="circle">
                <img
                  src={Social.LinkedIn}
                  alt="LinkedIn"
                  width="16px"
                  height="16px"
                />
              </Button>
            </a>

            <a
              href={ContentObject.Shared.clutchLink}
              target="_blank"
              rel="noopener noreferrer"
              className={css({
                marginLeft: theme.sizing.scale400,
                [theme.breakpoints.mediaQueries.small]: {
                  display: "none",
                },
              })}
            >
              <Button kind="circle">
                <img
                  src={Social.Clutch}
                  alt="Clutch"
                  width="16px"
                  height="16px"
                />
              </Button>
            </a>

            <span
              className={css({
                display: "block",
                background: theme.colors.backgroundLightAccent,
                marginLeft: theme.sizing.scale1000,
                marginRight: theme.sizing.scale1000,
                height: "1px",
                flexGrow: "1",
                [theme.breakpoints.mediaQueries.small]: {
                  display: "none",
                },
              })}
            ></span>

            <div
              className={css({
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                paddingTop: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale600,
                alignItems: "center",
              })}
            >
              <LabelXSmall
                $style={{
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  marginRight: theme.sizing.scale750,
                  whiteSpace: "nowrap",
                  [theme.breakpoints.mediaQueries.small]: {
                    display: "none",
                  },
                }}
              >
                <ContentItem scope="Home" accessor="lead.clutchLabel" />
              </LabelXSmall>
              <a
                href={ContentObject.Shared.clutchLink}
                target="_blank"
                rel="noopener noreferrer"
                className={css({
                  zIndex: "5",
                })}
              >
                <img alt="Clutch" src={clutchBadge} height="82px" />
              </a>
            </div>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Lead;
