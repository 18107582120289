import PropTypes from "prop-types";
import React from "react";
import { navigate } from "gatsby";

import { Grid, Cell } from "../../components/ui";

import {
  HeadingLarge,
  LabelLarge,
  LabelXSmall,
  ParagraphLarge,
} from "baseui/typography";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StyledLink } from "baseui/link";

import Technologies from "../../assets/images/technologies";

import chevronRight from "../../assets/images/chevron-right.svg";

import { ContentObject, FormattedContentItem } from "../../components/content";

import VisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";

import { useTheme } from "../../contexts/theme";

const ServicesCard = ({ title = "", number = "01", caption, to }) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      onClick={() => navigate(to)}
      overrides={{
        Block: {
          style: {
            borderTopWidth: "1px",
            borderRightWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderTopStyle: "solid",
            borderRightStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftStyle: "solid",
            borderTopColor: theme.colors.servicesCardBorder,
            borderRightColor: theme.colors.servicesCardBorder,
            borderBottomColor: theme.colors.servicesCardBorder,
            borderLeftColor: theme.colors.servicesCardBorder,
            height: "100%",
            padding: theme.sizing.scale1000,
            boxSizing: "border-box",
            position: "relative",
            transitionProperty: "all",
            transitionDuration: "200ms",
            transitionTimingFunction: "ease-in-out",
            ":hover": {
              boxShadow: `0px 0px 50px 0px ${theme.colors.servicesCardGlow}`,
              cursor: "pointer",
            },
            ":hover [data-csweb=service-learn-more]": {
              paddingRight: "20px",
            },
            ":hover [data-csweb=service-learn-more]::after": {
              width: "100%",
            },
            ":hover [data-csweb=service-learn-more] img": {
              opacity: "1",
            },
            [theme.breakpoints.mediaQueries.small]: {
              borderTopWidth: "0px",
              borderRightWidth: "0px",
              borderBottomWidth: "0px",
              borderLeftWidth: "0px",
              padding: theme.sizing.scale800,
            },
          },
        },
      }}
    >
      <div
        className={css({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          [theme.breakpoints.mediaQueries.small]: {
            justifyContent: "center",
            position: "relative",
          },
        })}
      >
        <LabelLarge
          color="contentAccent"
          $style={{
            position: "relative",
            zIndex: "5",
          }}
        >
          {title}
        </LabelLarge>

        <LabelLarge
          color="contentSecondary"
          $style={{
            fontWeight: "700",
            [theme.breakpoints.mediaQueries.small]: {
              position: "absolute",
              left: "50%",
              top: "0px",
              transform: "translateX(-50%)",
              fontSize: "50px",
              color: theme.colors.backgroundTertiary,
            },
          }}
          aria-disabled="true"
        >
          {number}
        </LabelLarge>
      </div>

      <ParagraphLarge
        paddingTop="scale600"
        paddingBottom="scale200"
        $style={{
          transitionProperty: "all",
          transitionDuration: "200ms",
          transitionTimingFunction: "ease-in-out",
        }}
      >
        {caption}
      </ParagraphLarge>

      <div
        className={css({
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        })}
      >
        <StyledLink
          data-csweb="service-learn-more"
          $as="div"
          $style={{
            position: "relative",
            lineHeight: "2",
            fontWeight: "700",
            fontSize: "15px",
            paddingRight: "0px",
            textDecoration: "none",
            transitionProperty: "all",
            transitionDuration: "400ms",
            transitionTimingFunction: "ease-in-out",
            color: theme.colors.contentAccent,
            "::before": {
              content: "''",
              position: "absolute",
              bottom: "0px",
              left: "0px",
              right: "0px",
              height: "1px",
              background: theme.colors.blue50,
            },
            "::after": {
              content: "''",
              position: "absolute",
              bottom: "0px",
              right: "0px",
              height: "1px",
              width: "0%",
              background: theme.colors.contentAccent,
              transitionProperty: "all",
              transitionDuration: "300ms",
              transitionTimingFunction: "ease-in-out",
            },
            ":hover": {
              color: theme.colors.contentAccent,
            },
          }}
        >
          learn more
          <img
            alt="More"
            src={chevronRight}
            height="14px"
            className={css({
              opacity: "0",
              position: "absolute",
              right: "0px",
              transitionProperty: "all",
              transitionDuration: "200ms",
              transitionDelay: "100ms",
              transitionTimingFunction: "ease-in-out",
              top: "50%",
              transform: "translateY(-50%)",
            })}
          />
        </StyledLink>
      </div>
    </Block>
  );
};

ServicesCard.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.string,
  caption: PropTypes.string,
  to: PropTypes.string,
};

const Services = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <article data-csweb="home-services">
      <Grid>
        <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
          <div
            className={css({
              paddingTop: theme.sizing.scale1200,
              paddingBottom: theme.sizing.scale1200,
            })}
          >
            <HeadingLarge
              $style={{
                textAlign: "center",
                marginBottom: theme.sizing.scale1600,
                [theme.breakpoints.mediaQueries.small]: {
                  ...theme.typography.HeadingMedium,
                },
              }}
            >
              <FormattedContentItem scope="Home" accessor="services.heading" />
            </HeadingLarge>

            <Grid
              flex
              flexGridColumnGap={["scale100", "scale1000", "scale2400"]}
              flexGridRowGap={["scale1000", "scale1000", "scale1200"]}
            >
              {ContentObject.Home.services.items.map((item, index) => (
                <Cell flex key={index}>
                  <VisibilitySensor
                    partialVisibility
                    offset={{
                      top: -10000,
                      bottom: 100,
                    }}
                  >
                    {({ isVisible }) => (
                      <Spring
                        config={{ tension: 120, friction: 14, duration: 100 }}
                        from={{ opacity: 0, x: 100 }}
                        to={
                          isVisible
                            ? { opacity: 1, x: 0 }
                            : { opacity: 0, x: 100 }
                        }
                      >
                        {({ x, ...style }) => (
                          <div
                            className={css({
                              transform: `translateX(${
                                index % 2 === 0 ? `-${x}` : `${x}`
                              }px)`,
                              [theme.breakpoints.mediaQueries.medium]: {
                                transform: "unset",
                              },
                              ...style,
                            })}
                          >
                            <ServicesCard
                              title={item.title}
                              number={item.number}
                              caption={item.caption}
                              to={item.to}
                            />
                          </div>
                        )}
                      </Spring>
                    )}
                  </VisibilitySensor>
                </Cell>
              ))}
            </Grid>

            <div
              className={css({
                paddingTop: theme.sizing.scale1600,
                paddingBottom: theme.sizing.scale1000,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                position: "relative",
                [theme.breakpoints.mediaQueries.medium]: {
                  paddingTop: theme.sizing.scale600,
                  paddingBottom: theme.sizing.scale300,
                  flexWrap: "wrap",
                  justifyContent: "center",
                },
              })}
            >
              {ContentObject.Home.services.technologies.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={css({
                      paddingTop: theme.sizing.scale900,
                      paddingBottom: theme.sizing.scale1200,
                      textAlign: "center",
                      height: "130px",
                      position: "relative",
                      display: "flex",
                      minWidth: `40px`,
                      alignItems: "center",
                      justifyContent: "center",
                      boxSizing: "border-box",
                      transitionProperty: "all",
                      transitionDuration: "200ms",
                      transitionTimingFunction: "ease-in-out",
                      ":hover": {
                        paddingTop: theme.sizing.scale500,
                      },
                      ":hover div": {
                        bottom: "25px",
                        opacity: "1",
                      },
                      [theme.breakpoints.mediaQueries.medium]: {
                        marginLeft: theme.sizing.scale400,
                        marginRight: theme.sizing.scale400,
                        marginTop: theme.sizing.scale0,
                        marginBottom: theme.sizing.scale0,
                        paddingBottom: theme.sizing.scale900,
                        height: "auto",
                        ":hover": {
                          paddingTop: theme.sizing.scale200,
                        },
                      },
                      [theme.breakpoints.mediaQueries.small]: {
                        paddingTop: theme.sizing.scale200,
                      },
                    })}
                  >
                    <img
                      src={Technologies[item].icon}
                      alt={Technologies[item].label}
                      className={css({
                        maxWidth: "70px",
                        maxHeight: "45px",
                        filter: useDarkTheme
                          ? "brightness(0) invert(1)"
                          : "invert(0)",
                        [theme.breakpoints.mediaQueries.medium]: {
                          maxWidth: "100px",
                          maxHeight: "35px",
                        },
                      })}
                    />
                    <LabelXSmall
                      $style={{
                        position: "absolute",
                        textTransform: "uppercase",
                        letterSpacing: "2.5px",
                        paddingLeft: "2.5px",
                        fontSize: "10px",
                        bottom: "-20px",
                        transitionProperty: "all",
                        transitionDuration: "200ms",
                        transitionTimingFunction: "ease-in-out",
                        opacity: "0",
                        whiteSpace: "nowrap",
                        [theme.breakpoints.mediaQueries.medium]: {
                          display: "none",
                        },
                      }}
                    >
                      {Technologies[item].label}
                    </LabelXSmall>
                  </div>
                );
              })}
            </div>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

export default Services;
